import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * Care Management page Numbers component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Numbers />
 */

const Numbers = () => {
  const {
    contentfulCareManagement: {
      firstNumbers,
      firstNumbersCopy,
      secondNumbers,
      secondNumbersCopy,
      thirdNumbers,
      thirdNumbersCopy,
    },
  } = useStaticQuery(graphql`
    query cmNumbers {
      contentfulCareManagement {
        firstNumbers
        firstNumbersCopy
        secondNumbers
        secondNumbersCopy
        thirdNumbers
        thirdNumbersCopy
      }
    }
  `);

  return (
    <ul
      className={cx(
        base.flex,
        base.itemsStart,
        base.justifyBetween,
        base.mt0,
        base.w80,
        main.dataItem,
        main.dataItemNumbers,
        main.mb120
      )}
    >
      <li
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          main.cmNumbers
        )}
      >
        <p
          className={cx(
            base.ma0,
            base.mb1,
            main.heading,
            theme.colorTeal,
            theme.utilTextCenter
          )}
        >
          {firstNumbers}
        </p>
        <p
          className={cx(
            base.ma0,
            base.tc,
            main.pCopySm,
            theme.colorTextGrey,
            theme.utilTextCenter
          )}
        >
          {firstNumbersCopy}
        </p>
      </li>

      <li
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          main.cmNumbers
        )}
      >
        <p
          className={cx(
            base.mb1,
            base.ma0,
            main.heading,
            theme.colorViolet,
            theme.utilTextCenter
          )}
        >
          {secondNumbers}
        </p>
        <p
          className={cx(
            base.ma0,
            base.tc,
            main.pCopySm,
            theme.colorTextGrey,
            theme.utilTextCenter
          )}
        >
          {secondNumbersCopy}
        </p>
      </li>

      <li
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          main.cmNumbers
        )}
      >
        <p
          className={cx(
            base.ma0,
            base.mb1,
            main.heading,
            theme.colorBlue,
            theme.utilTextCenter
          )}
        >
          {thirdNumbers}
        </p>
        <p
          className={cx(
            base.ma0,
            base.tc,
            main.pCopySm,
            theme.colorTextGrey,
            theme.utilTextCenter
          )}
        >
          {thirdNumbersCopy}
        </p>
      </li>
    </ul>
  );
};

export default Numbers;
