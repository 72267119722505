import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

const copyImg = {
  maxHeight: '290px',
  maxWidth: '290px',
};

/**
 * Care Management page Pinpoint component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Pinpoint />
 */

const Pinpoint = () => {
  const {
    contentfulCareManagement: {
      pinpointContent: {
        childMarkdownRemark: { html },
      },
      pinpointImage: { fluid },
      pinpointTitle,
    },
  } = useStaticQuery(graphql`
    query Pinpoint {
      contentfulCareManagement {
        pinpointContent {
          childMarkdownRemark {
            html
          }
        }
        pinpointImage {
          fluid(maxHeight: 350, maxWidth: 350, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        pinpointTitle
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexWrap,
        base.itemsCenter,
        base.justifyBetween,
        base.w100,
        main.cmWrapper,
        main.dataItem,
        main.mb120
      )}
    >
      <Img
        fluid={fluid}
        alt="Archery target with a caduceuson on the bullseye."
        style={copyImg}
        imgStyle={copyImg}
      />
      <div className={cx(base.ma2, base.w100, main.imgCopyWrap)}>
        <p className={cx(base.ma0, base.mb2, main.subHeading)}>
          {pinpointTitle}
        </p>
        <section className={cx(base.ma0, main.stCopy, main.cmPinpointCopy)}>
          <CleanHTML html={html} />
        </section>
      </div>
    </section>
  );
};

export default Pinpoint;
