import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

/**
 * Care Management page Avoidables component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Avoidables />
 */

const Avoidables = () => {
  const {
    contentfulCareManagement: {
      avoidableModuleContent: {
        childMarkdownRemark: { html },
      },
      avoidableModuleTitle,
      avoidableTitle,
      firstAvoidableItemIcon,
      firstAvoidableItemTitle,
      fourthAvoidableItemIcon,
      fourthAvoidableItemTitle,
      secondAvoidableItemIcon,
      secondAvoidableItemTitle,
      thirdAvoidableItemIcon,
      thirdAvoidableItemTitle,
    },
  } = useStaticQuery(graphql`
    query Avoidables {
      contentfulCareManagement {
        avoidableModuleContent {
          childMarkdownRemark {
            html
          }
        }
        avoidableModuleTitle
        avoidableTitle
        firstAvoidableItemIcon {
          file {
            url
          }
        }
        firstAvoidableItemTitle
        secondAvoidableItemIcon {
          file {
            url
          }
        }
        secondAvoidableItemTitle
        thirdAvoidableItemIcon {
          file {
            url
          }
        }
        thirdAvoidableItemTitle
        fourthAvoidableItemIcon {
          file {
            url
          }
        }
        fourthAvoidableItemTitle
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexWrap,
        base.itemsCenter,
        base.justifyBetween,
        base.w100,
        main.dataItem,
        main.cmWrapper,
        main.mb90,
        main.mt90
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyBetween,
          base.w100,
          main.cmPredict
        )}
      >
        <p className={cx(base.tc, main.subHeading, theme.colorDark)}>
          {avoidableTitle}
        </p>
        <ul
          className={cx(
            base.flex,
            base.flexWrap,
            base.itemsStart,
            base.justifyBetween,
            base.ma0,
            base.pa0,
            base.w100,
            main.cmAvoidables
          )}
        >
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsCenter,
              base.justifyBetween,
              main.predictItem
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                main.cmIconWrap,
                theme.shapeWhite
              )}
            >
              <img
                src={firstAvoidableItemIcon.file.url}
                alt="Ambulance."
                className={main.cmIconImg}
              />
            </div>
            <p
              className={cx(
                base.ma0,
                base.tc,
                main.cmAvoidablesTitle,
                theme.colorDark,
                theme.title3
              )}
            >
              {firstAvoidableItemTitle}
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsCenter,
              base.justifyBetween,
              main.predictItem
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                main.cmIconWrap,
                theme.shapeWhite
              )}
            >
              <img
                src={secondAvoidableItemIcon.file.url}
                alt="Hospital Bed."
                className={main.cmIconImg}
              />
            </div>
            <p
              className={cx(
                base.ma0,
                base.tc,
                main.cmAvoidablesTitle,
                theme.colorDark,
                theme.title3
              )}
            >
              {secondAvoidableItemTitle}
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsCenter,
              base.justifyBetween,
              main.predictItem
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                main.cmIconWrap,
                theme.shapeWhite
              )}
            >
              <img
                src={thirdAvoidableItemIcon.file.url}
                alt="Stethoscope."
                className={main.cmIconImg}
              />
            </div>
            <p
              className={cx(
                base.ma0,
                base.tc,
                main.cmAvoidablesTitle,
                theme.colorDark,
                theme.title3
              )}
            >
              {thirdAvoidableItemTitle}
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.flexColumn,
              base.itemsCenter,
              base.justifyBetween,
              main.predictItem
            )}
          >
            <div
              className={cx(
                base.flex,
                base.itemsCenter,
                base.justifyCenter,
                main.cmIconWrap,
                theme.shapeWhite
              )}
            >
              <img
                src={fourthAvoidableItemIcon.file.url}
                alt="Medical chart."
                className={main.cmIconImg}
              />
            </div>
            <p
              className={cx(
                base.ma0,
                base.tc,
                main.cmAvoidablesTitle,
                theme.colorDark,
                theme.title3
              )}
            >
              {fourthAvoidableItemTitle}
            </p>
          </li>
        </ul>
      </div>
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyStart,
          base.overflowHidden,
          base.relative,
          base.w100,
          main.cmHow
        )}
      >
        <p className={cx(theme.colorDark, theme.title2, base.mb3)}>
          {avoidableModuleTitle}
        </p>
        <div className={cx(theme.colorDark, main.stCopy)}>
          <CleanHTML html={html} />
        </div>
        <Link
          to="/contact"
          className={cx(
            main.button,
            theme.colorWhite,
            theme.dpBtnViolet,
            theme.title3
          )}
        >
          Learn more<span className={main.srh}> about Decision Point</span>
        </Link>
      </div>
    </section>
  );
};

export default Avoidables;
