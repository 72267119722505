import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

const sizing = {
  height: '70vh',
  width: '100vw',
};

const wrap = {
  ...sizing,
};

const image = {
  ...sizing,
};

/**
 * Care Management page Hero component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Hero />
 */

const Hero = () => {
  const {
    contentfulCareManagement: {
      heroImage: { fixed },
      heroTitle,
      heroSubtitle,
    },
  } = useStaticQuery(graphql`
    query cmHero {
      contentfulCareManagement {
        heroImage {
          fixed(height: 667, width: 1600, quality: 85, resizingBehavior: FILL) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        heroSubtitle
        heroTitle
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.itemsCenter,
        base.justifyStart,
        base.w100,
        main.hero
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.relative,
          base.w100,
          main.heroInner
        )}
      >
        <Img
          fixed={fixed}
          alt="Nurse helping an elderly person."
          loading="eager"
          objectPosition="40% top"
          style={wrap}
          imgStyle={image}
          className={main.cmHeroImg}
        />
        <div className={cx(base.pa5, main.heroCopy)}>
          <p className={cx(base.ma0, base.mb1, theme.colorGrey, theme.title2)}>
            {heroTitle}
          </p>
          <h1
            className={cx(main.heading, main.heroTitleViolet, theme.colorGrey)}
          >
            <CleanHTML html={heroSubtitle} />
          </h1>
        </div>
      </div>
    </section>
  );
};

export default Hero;
