import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CleanHTML from '../cleanHTML';

/**
 * Care Management page BottomCopy component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <BottomCopy />
 */

const BottomCopy = () => {
  const {
    contentfulCareManagement: {
      bottomContent: {
        childMarkdownRemark: { html },
      },
      bottomSubtitle,
      bottomTitle,
    },
  } = useStaticQuery(graphql`
    query BottomCopy {
      contentfulCareManagement {
        bottomContent {
          childMarkdownRemark {
            html
          }
        }
        bottomSubtitle
        bottomTitle
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.justifyCenter,
        base.itemsCenter,
        base.w100,
        main.cmPlans,
        main.mb120
      )}
    >
      <p className={cx(base.ma0, base.tc, main.subHeading, theme.colorGrey)}>
        {bottomTitle}
      </p>
      <p
        className={cx(
          base.ma0,
          base.mb4,
          base.tc,
          theme.colorTextGrey,
          theme.title2
        )}
      >
        {bottomSubtitle}
      </p>
      <div
        className={cx(
          theme.colorDark,
          base.ma0,
          base.tc,
          base.w100,
          main.cmPlansCopy,
          main.stCopy
        )}
      >
        <CleanHTML html={html} />
      </div>
    </section>
  );
};

export default BottomCopy;
