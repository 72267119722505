import React, { useState } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import BottomCopy from './cmBottomCopy';

const sizes = {
  height: '580px',
  maxWidth: '2060px',
};

const leftWrapStyles = {
  ...sizes,
  left: '0',
  zIndex: '5',
};

const rightWrapStyles = {
  ...sizes,
  right: '0',
  zIndex: '5',
};

const imgStyles = {
  ...sizes,
};

/**
 * Care Management page Resizable component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Resizable />
 */

const Resizable = () => {
  const {
    contentfulCareManagement: {
      fifthResizableCoordinationCopy,
      fifthResizableCoordinationIcon,
      firstResizableCoordinationCopy,
      firstResizableCoordinationIcon,
      firstResizableManagementCopy,
      firstResizableManagementIcon,
      fourthResizableCoordinationCopy,
      fourthResizableCoordinationIcon,
      fourthResizableManagementCopy,
      fourthResizableManagementIcon,
      resizableCoordinationImage,
      resizableCoordinationSubtitle,
      resizableCoordinationTitle,
      resizableManagementImage,
      resizableManagementSubtitle,
      resizableManagementTitle,
      secondResizableCoordinationCopy,
      secondResizableCoordinationIcon,
      secondResizableManagementCopy,
      secondResizableManagementIcon,
      thirdResizableCoordinationCopy,
      thirdResizableCoordinationIcon,
      thirdResizableManagementCopy,
      thirdResizableManagementIcon,
    },
  } = useStaticQuery(graphql`
    query Resizable {
      contentfulCareManagement {
        fifthResizableCoordinationCopy
        fifthResizableCoordinationIcon {
          file {
            url
          }
        }
        firstResizableCoordinationCopy
        firstResizableCoordinationIcon {
          file {
            url
          }
        }
        firstResizableManagementCopy
        firstResizableManagementIcon {
          file {
            url
          }
        }
        fourthResizableCoordinationCopy
        fourthResizableCoordinationIcon {
          file {
            url
          }
        }
        fourthResizableManagementCopy
        fourthResizableManagementIcon {
          file {
            url
          }
        }
        secondResizableCoordinationCopy
        secondResizableCoordinationIcon {
          file {
            url
          }
        }
        secondResizableManagementCopy
        secondResizableManagementIcon {
          file {
            url
          }
        }
        thirdResizableCoordinationCopy
        thirdResizableCoordinationIcon {
          file {
            url
          }
        }
        thirdResizableManagementCopy
        thirdResizableManagementIcon {
          file {
            url
          }
        }
        resizableCoordinationImage {
          fluid(
            maxHeight: 680
            maxWidth: 2416
            quality: 80
            resizingBehavior: FILL
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        resizableCoordinationSubtitle
        resizableCoordinationTitle
        resizableManagementImage {
          fluid(
            maxHeight: 680
            maxWidth: 2416
            quality: 80
            resizingBehavior: FILL
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        resizableManagementSubtitle
        resizableManagementTitle
      }
    }
  `);

  // determine which side is being hovered. only one should be active at a time.
  const [state, setState] = useState({
    leftHover: false,
    rightHover: false,
  });

  // open left and close right
  const leftHover = () => {
    setState(prev => ({
      ...prev,
      leftHover: !state.leftHover,
    }));
  };

  // open right and close left
  const rightHover = () => {
    setState(prev => ({
      ...prev,
      rightHover: !state.rightHover,
    }));
  };

  return (
    <>
      <BottomCopy />
      <section
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyStart,
          base.relative,
          base.w100,
          main.resizable
        )}
      >
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyBetween,
            base.overflowHidden,
            base.relative,
            main.resizableOuter,
            { [main.resizableInactive]: state.rightHover }
          )}
          onMouseEnter={leftHover}
          onMouseLeave={leftHover}
        >
          <div
            className={cx(
              base.absolute,
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.w100,
              main.resizableCopy,
              main.resizableCopyLeft
            )}
          >
            <p className={cx(base.mb3, theme.colorDark, theme.title2)}>
              {resizableCoordinationSubtitle}
            </p>
            <ul
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsStart,
                base.justifyStart,
                base.ma0,
                base.pa0
              )}
            >
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={firstResizableCoordinationIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {firstResizableCoordinationCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={secondResizableCoordinationIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {secondResizableCoordinationCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={thirdResizableCoordinationIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {thirdResizableCoordinationCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={fourthResizableCoordinationIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {fourthResizableCoordinationCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={fifthResizableCoordinationIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {fifthResizableCoordinationCopy}
                </p>
              </li>
            </ul>
          </div>
          <div
            className={cx(
              base.absolute,
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.right0,
              base.w100,
              main.resizableTitle,
              main.resizableTitleLeft
            )}
          >
            <p className={cx(base.ma0, main.heading)}>
              {resizableCoordinationTitle}
            </p>
          </div>
          <Img
            fluid={resizableCoordinationImage.fluid}
            alt="A couple sitting in a couch."
            objectPosition="left center"
            style={leftWrapStyles}
            imgStyle={imgStyles}
          />
        </div>
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsEnd,
            base.justifyBetween,
            base.overflowHidden,
            base.relative,
            main.resizableInner,
            { [main.resizableInactive]: state.leftHover }
          )}
          onMouseEnter={rightHover}
          onMouseLeave={rightHover}
        >
          <div
            className={cx(
              base.absolute,
              base.flex,
              base.flexColumn,
              base.itemsStart,
              base.justifyStart,
              base.w100,
              main.resizableCopy,
              main.resizableCopyRight
            )}
          >
            <p className={cx(base.mb3, theme.colorDark, theme.title2)}>
              {resizableManagementSubtitle}
            </p>
            <ul
              className={cx(
                base.flex,
                base.flexColumn,
                base.itemsStart,
                base.justifyStart,
                base.ma0,
                base.pa0
              )}
            >
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={firstResizableManagementIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {firstResizableManagementCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={secondResizableManagementIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {secondResizableManagementCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={thirdResizableManagementIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {thirdResizableManagementCopy}
                </p>
              </li>
              <li
                className={cx(
                  base.flex,
                  base.itemsCenter,
                  base.justifyStart,
                  base.mb2
                )}
              >
                <img
                  src={fourthResizableManagementIcon.file.url}
                  alt=""
                  aria-hidden="true"
                  className={cx(base.mr3, main.resizableIcon)}
                />
                <p className={cx(base.ma0, main.stCopy, theme.colorDark)}>
                  {fourthResizableManagementCopy}
                </p>
              </li>
            </ul>
          </div>
          <div
            className={cx(
              base.absolute,
              base.flex,
              base.flexColumn,
              base.itemsEnd,
              base.justifyStart,
              base.left0,
              base.w100,
              main.resizableTitle,
              main.resizableTitleRight
            )}
          >
            <p className={cx(base.ma0, main.heading)}>
              {resizableManagementTitle}
            </p>
          </div>
          <Img
            fluid={resizableManagementImage.fluid}
            alt="A person sleeping in a couch."
            objectPosition="right center"
            style={rightWrapStyles}
            imgStyle={imgStyles}
          />
        </div>
      </section>
    </>
  );
};

export default Resizable;
