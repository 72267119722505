import React from 'react';
import PropTypes from 'prop-types';

import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import Avoidables from '../../components/care-management/cmAvoidables';
import CTA from '../../components/ctaContainer';
import Hero from '../../components/care-management/cmHero';
import Layout from '../../components/layout';
import Numbers from '../../components/care-management/cmNumbers';
import Pinpoint from '../../components/care-management/cmPinpoint';
import Resizable from '../../components/care-management/cmResizable';

const pageDescription =
  'Healthier members through data-driven decisions. Decreased utilization and reduced admissions start with deep data insights.';

/**
 * Care Management page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Care location={string} />
 */

const Care = ({ location }) => (
  <Layout
    pageTitle="Care Management"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <Avoidables />
    <Pinpoint />
    <Numbers />
    <Resizable />
    <CTA
      background={main.colorGradientViolet}
      button={theme.dpBtnViolet}
      color={theme.colorViolet}
      solution="profile"
    />
  </Layout>
);

Care.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Care;
